<template>
  <div>
    <router-view v-if="showNestedChildRoute"></router-view>
    <div v-if="!haveNestedChildRoute && initialized">
      <CRow class="align-items-center mb-3" v-if="market.id">
        <CCol col="auto" class="mt-2 mb-1 my-sm-0">
          <CButton color="outline-primary" size="sm" @click="onBack()">
            <font-awesome-icon icon="arrow-left" />
          </CButton>
        </CCol>
        <CCol sm>
          <div class="ui-type-display-lg">
            {{ marketName(market) }}
          </div>
          <div v-html="market.market.role.desc_short"></div>
          <div>
            <span class="mr-1"
              >{{ $t('family.market.showcase.time_limit') }}
              <strong>{{ timelimitToOrder(market, $i18n.locale) }}</strong></span
            >
            <span v-if="showDelivery(market)" class="mr-1"
              >| {{ $t('family.market.showcase.delivery') }} <strong>{{ deliveryDate(market) }}</strong></span
            >
            <span v-if="showPickup(market)" class="mr-1"
              >| {{ $t('family.market.showcase.pickup') }} <strong>{{ pickupDate(market) }}</strong></span
            >
            <a
              href="#0"
              v-if="showPickup(market)"
              @click.prevent="showMarketDeliveryMap = true"
              >{{ $t('family.market.showcase.pickup_list') }}</a
            >
          </div>

          <MarketDeliveryMapModal
            v-if="showMarketDeliveryMap"
            :show.sync="showMarketDeliveryMap"
            :market="market.market"
            :familyId="familyId"
            :title="`${marketName(market)} - Punti di ritiro`"
          />
        </CCol>
        <CCol col="auto" class="ml-auto text-right">
          <MarketCartButton />
          <div class="ui-type-caption text-gray-600" v-if="minAmountToOrder(market, currencySymbol)!='--'">
            {{ $t('family.market.showcase.min_order') }}
            <strong>{{ minAmountToOrder(market, currencySymbol) }}</strong>
          </div>
        </CCol>
      </CRow>

      <div v-else class="loading text-center">
        <CSpinner color="primary" size="lg" />
      </div>

      <CCard v-if="market">
        <CCardBody>
          <MarketShowcaseFilters
            :viewType.sync="filter.viewType"
            :search.sync="filter.search"
            :producerId.sync="filter.producerId"
            :producerOptions="filter.producerOptions"
            :categoryId.sync="filter.categoryId"
            :categoryOptions="filter.categoryOptions"
            :certificationsIds.sync="filter.certificationsIds"
            :certificationsOptions="filter.certificationsOptions"
            @onSearch="applyFilter"
          />
          <MarketShowcaseGrid
            v-show="filter.viewType === 'grid'"
            :filter="filter"
            :bus="bus"
            @onSearch="applyFilter"
          />
          <MarketShowcaseTable
            v-show="filter.viewType === 'table'"
            :filter="filter"
            :bus="bus"
            @onSearch="applyFilter"
          />
        </CCardBody>
      </CCard>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { GetDataManagerNew, ApiCall } from "../../ds";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { mapState, mapGetters } from "vuex";
import {
  MarketCartButton,
  MarketShowcaseFilters,
  MarketShowcaseGrid,
  MarketShowcaseTable,
  MarketDeliveryMapModal,
} from "./partials";
import get from "lodash/get";
import {
  getCertificationsOptions,
  getMarketCategoryTree,
  getMarketProducersOptions,
} from "../../helpers/options";
import marketMixin from "../../mixins/market";

export default {
  name: "UiMarketShowcase",

  mixins: [marketMixin],

  components: {
    MarketCartButton,
    MarketShowcaseFilters,
    MarketShowcaseGrid,
    MarketShowcaseTable,
    MarketDeliveryMapModal,
  },

  data() {
    return {
      initialized: false,
      currentPage: 1,
      bus: new Vue(),
      showMarketDeliveryMap: false,
      filter: {
        viewType: "grid", // grid | table
        search: "",
        producerId: null,
        producerOptions: [],
        categoryId: null,
        categoryOptions: [],
        certificationsIds: [],
        certificationsOptions: [],
      },
    };
  },
  computed: {
    haveNestedChildRoute() {
      const { productId } = this.$route.params;
      return productId && !isNaN(parseInt(productId, 10));
    },

    showNestedChildRoute() {
      const { productId } = this.$route.params;
      return productId && !isNaN(parseInt(productId, 10)) && this.initialized;
    },

    user_lang() {
      return this.$i18n.locale
    },

    ...mapState("shop", ["market"]),

    ...mapGetters("connections", ["currencySymbol", "familyId"]),
  },

  mounted() {
    this.init();
  },

  watch: {
    $route: "init",
    user_lang(new_lang, old_lang) {
      if (new_lang!=old_lang) {
        this.filter.categoryOptions=[];
        this.loadOptions();
      }
    }
  },

  methods: {
    init() {
      const self = this;
      self.initialized = false;
      this.loadMarketData().then(() => {
        self.initialized = true;
        self.LoadExistingCart();
        if (!self.haveNestedChildRoute) {
          self.loadOptions();
        }
      });
    },

    loadMarketData() {
      const self = this;
      return new Promise((resolve, reject) => {
        const marketId = get(self.market, "market.id", null);
        if (marketId != self.$route.params.marketId) {
          self.$store.commit("shop/setMarket", {});
          console.log("Load market data");
          const predicate = new Predicate(
            "market.id",
            "equal",
            self.$route.params.marketId
          );
          ApiCall(
            GetDataManagerNew("family_markets", [this.familyId]),
            new Query().where(predicate),
            (response) => {
              const marketData = response.result[0];
              self.$store.commit("shop/setMarket", marketData);
              resolve(marketData);
            },
            (e) => {
              reject(e);
            }
          );
        } else {
          resolve(self.market);
        }
      });
    },

    loadOptions() {
      const self = this;
      const lang = this.$i18n.locale;
      const familyId = this.$store.state.connections.current.family.id;
      const marketId = this.market.market.id;

      getMarketCategoryTree({
        lang,
        familyId,
        marketId,
      }).then((options) => {
        self.filter.categoryOptions = [
          { value: null, label: "- "+self.$t('common.all')+" -" },
          ...options,
        ];
      });
      getMarketProducersOptions({
        lang,
        familyId,
        marketId,
      }).then((options) => {
        self.filter.producerOptions = [
          { value: null, label: "- "+self.$t('common.all')+" -" },
          ...options,
        ];
      });
      getCertificationsOptions().then((options) => {
        self.filter.certificationsOptions = [...options];
      });
    },

    applyFilter({ filter }) {
      this.bus.$emit("onSearch", filter);
    },

    onBack() {
      const familyId = this.$store.state.connections.current.family.id;
      this.$router.push({ name: "UserMarketList", params: { id: familyId } });
    },

    LoadExistingCart() {
      this.$store.dispatch("shop/loadCart");
    },
  },
};
</script>
